import moment from "moment"

export const userLicenseInsuranceTransformer = (data) => {

    let insuranceBack = data.vehicle_insurance.images.insurance_card_back_image
    let insuranceFront = data.vehicle_insurance.images.insurance_card_front_image

    let licenseBack = data.drivers_license.images.drivers_license_back_image
    let licenseFront = data.drivers_license.images.drivers_license_front_image

    let licenseBackThumb = data.drivers_license.images.drivers_license_back_thumbnail
    let licenseFrontThumb = data.drivers_license.images.drivers_license_front_thumbnail

    let insuranceBackThumb = data.vehicle_insurance.images.insurance_card_back_thumbnail
    let insuranceFrontThumb = data.vehicle_insurance.images.insurance_card_front_thumbnail

    let insurance_company = data.vehicle_insurance.company
    let insurance_name_on_policy = data.vehicle_insurance.name_on_policy
    let insurance_policy_number = data.vehicle_insurance.policy_number
    let insurance_policy_end_at = data.vehicle_insurance.policy_end_at
    let insurance_policy_start_at = data.vehicle_insurance.policy_start_at

    let formattedLicenseInsurance = {
        "insurance_front_image" : !insuranceFront ? null : insuranceFront.url,
        "insurance_back_image" : !insuranceBack ? null : insuranceBack.url,

        "insurance_front_image_id" : !insuranceFront ? null : !insuranceFront.cloudinary_public_id ? insuranceFront.url.split("v1/")[1]: insuranceFront.cloudinary_public_id,
        "insurance_back_image_id" : !insuranceBack ? null : !insuranceBack.cloudinary_public_id ? insuranceBack.url.split("v1/")[1]: insuranceBack.cloudinary_public_id,        

        "insurance_front_thumbnail" : !insuranceFrontThumb ? null : insuranceFrontThumb.url,
        "insurance_back_thumbnail" : !insuranceBackThumb ? null : insuranceBackThumb.url,     

        "vehicle_insurance": {
            "company": !insurance_company ? null : insurance_company,
            "name_on_policy": !insurance_name_on_policy ? null : insurance_name_on_policy,
            "policy_number": !insurance_policy_number ? null : insurance_policy_number,
            "policy_end_at": !insurance_policy_end_at ? null : moment.utc(insurance_policy_end_at),
            "policy_start_at": !insurance_policy_start_at ? null : moment.utc(insurance_policy_start_at)
        },

        "license_front_image" : !licenseFront ? null : licenseFront.url, //for showing full size
        "license_back_image" : !licenseBack ? null : licenseBack.url, //for showing full size

        "license_front_image_id" : !licenseFront ? null : licenseFront.cloudinary_public_id, //set these so we can resave them
        "license_back_image_id" : !licenseBack ? null : licenseBack.cloudinary_public_id, //set these so we can resave them

        "license_front_thumbnail" : !licenseFrontThumb ? null : licenseFrontThumb.url, //this is the small clickable one
        "license_back_thumbnail" : !licenseBackThumb ? null : licenseBackThumb.url,  //this is the small clickable one
        
        
        
        //"drivers_license_issuing_country" : data.drivers_license.license_country_abbreviation,
        //"drivers_license_issuing_state" : data.drivers_license.license_state_abbreviation,
        "drivers_license_number" : data.drivers_license.license_no,
        "drivers_license_first_name" : data.drivers_license.first_name,
        "drivers_license_last_name" : data.drivers_license.last_name,
        "drivers_license_middle_name" : data.drivers_license.middle_name,
        "drivers_license_date_of_birth" : data.drivers_license.date_of_birth,
        "drivers_license_exp" : data.drivers_license.expiration_date,

        "drivers_license_gender" : data.drivers_license.gender,

        "locked" : false, //data.drivers_license.verification_locked, Come back to this later.

        "address_line_1" : data.drivers_license.location.address_street_1,
        "address_line_2" : data.drivers_license.location.address_street_2,
        "address_city" : data.drivers_license.location.city,
        "address_region" : data.drivers_license.location.region_abbreviation,
        "address_country" : data.drivers_license.location.country_abbreviation,
        "address_postal_code" : data.drivers_license.location.postal_code
    }
    

return formattedLicenseInsurance

}
