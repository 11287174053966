import { AUTHENTICATION_CALLS } from './authentication.js'
import { DEALER_CALLS } from './dealer.js'
import { LISTINGS_CALLS } from './listings.js'
import { MARKETING_CALLS } from './marketing.js'
import { PAYMENTS_CALLS } from './payments.js'
import { REFERRALS_CALLS } from './referrals.js'
import { RESERVATION_CALLS } from './reservationRequests.js'
import { TRIPS_CALLS } from './trips.js'
import { USERS_CALLS } from './users.js'
import { UTILS_CALLS } from './utils.js'
import { CHAT_SESSION_CALLS } from './chatSession.js'
import { MOTORCYCLE_EVENTS_CALLS } from './motorcycleEvents'

//Eventually, I'd like to remove all the transformers
//As of Aug 2020 all the Mock Calls Are broken. 
//Need to redo this feature if we want to continue using it. 

export const CALL_LIBRARY = { 
    ...AUTHENTICATION_CALLS,
    ...DEALER_CALLS,
    ...LISTINGS_CALLS,
    ...MARKETING_CALLS,
    ...PAYMENTS_CALLS,
    ...REFERRALS_CALLS,
    ...RESERVATION_CALLS,
    ...TRIPS_CALLS,
    ...USERS_CALLS,
    ...UTILS_CALLS,
    ...CHAT_SESSION_CALLS,
    ...MOTORCYCLE_EVENTS_CALLS
}

